export default {
  props: {
    bVisible: {
      type: Boolean,
    },
    inCard:{
      type: Boolean,
    },
    item:{
      type: Object
    }
  },
  data() {
    return {
      texts: "",
      title: "",
      descripcion: "",
      note:"",
      chkLabel:"",
      api: "",
      returnMain:false,
      dobleConfirm:false,
      chkDelete:false,
      bLoading: false,
      screenWidth: 0,
      loadingStatus:false
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 45;
      } else {
        this.screenWidth = 100;
      }
    },
    changeStatus(){
      this.loadingStatus = true
      if (!this.inCard) {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
        const payload = {
        };
  
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/products/${this.item.sProductId}/visible/${!(this.item.bVisible)}`,
          payload, config
        )
          .then((response) => {
            this.loadingStatus = false
            this.mixSuccess(response.data.message);
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.loadingStatus = false
            this.mixError(error.response.data.message,error.response.status);
          });
      }
    }
  },
  watch: {

    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
};